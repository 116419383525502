import styled from 'styled-components'

export const Container = styled.section`
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${p => p.theme.mediaQueries.mobile} {
    padding-inline: ${p => p.theme.padding.mobileBlock}px;
  }

  img {
    transition: all ${p => p.theme.transitions.default};
    filter: ${p => p.theme.colors.iconCarrierFilter};
    opacity: ${p => p.theme.opacity.normalIcon};
    cursor: pointer;
    object-fit: contain;

    &:hover {
      filter: grayscale(0%);
      opacity: 1;
    }

    ${p => p.theme.mediaQueries.mobile} {
      height: 32px;
      width: 100%;
    }
  }
`
